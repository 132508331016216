import React from "react";
import { graphql } from "gatsby";
import TemplatesList from "@views/TemplatesList";
import Layout from "../ui/layout";
import { TemplatesViewQueryQuery } from "@gen/gatsby-graphql";
import Banner from "@components/Banner/Banner";

interface TemplatesProps {
  data: TemplatesViewQueryQuery;
}

const TemplatesPage: React.FC<TemplatesProps> = props => {
  const templates = props.data.allTemplate.edges.map(
    (item: any) => item.node.data,
  );

  return (
    <Layout
      header={{ color: "black" }}
      seo={{
        title: "React Templates and Themes Search",
        description:
          "Reactemplates. Search through a variety of React Templates that contains technologies like styled-components, Typescript, Gatsby, Next.js etc",
      }}
    >
      <TemplatesList templates={templates} />
    </Layout>
  );
};

export const query = graphql`
  query TemplatesAllQuery {
    allTemplate {
      edges {
        node {
          id
          data {
            slug
            name
            description {
              bigDescription
              shortDescription
              tech
              features {
                key
                text
              }
              category
            }
            price {
              initialPrice
              currentPrice
            }
            thumbnailImages {
              thumbnail
            }
          }
        }
      }
    }
  }
`;

export default TemplatesPage;
